import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import {
  DateField,
  Datagrid,
  EditButton,
  Filter,
  FunctionField,
  List,
  SelectInput,
  TextField,
  TextInput,
  TopToolbar,
  useRefresh,
} from 'react-admin';

import MEAFraisFixeOnlyCreateEditDialog from './CreateEditDialog';
import { getMeaSpaces, getMeaSpaceNameFromId } from './meaSpace';

function MEAFraisFixeOnlyComponent(props) {
  const [isDisplayDialog, setDisplayDialog] = useState(false);
  const [meaFraisFixeOnlyToEdit, setMeaFraisFixeOnlyToEdit] = useState();
  const refreshList = useRefresh();

  const handleCreateButton = () => {
    setMeaFraisFixeOnlyToEdit(undefined);
    setDisplayDialog(true);
  };

  const closeDialogAction = (shouldRefresh) => {
    setDisplayDialog(false);
    if (shouldRefresh) refreshList();
  };

  const openEditDialog = (event, meaFraiFixeOnly) => {
    event.preventDefault();
    setMeaFraisFixeOnlyToEdit(meaFraiFixeOnly);
    setDisplayDialog(true);
  };

  const ListActions = () => (
    <TopToolbar>
      <Button
        variant="contained"
        color="primary"
        onClick={handleCreateButton}
      >
        Create
      </Button>
    </TopToolbar>
  );

  const ListFilter = (filterProps) => (
    <Filter variant="standard" {...filterProps}>
        <TextInput label="Recherche" source="q" alwaysOn />
        <SelectInput label="Espace MEA" source="meaSpace" choices={getMeaSpaces()} alwaysOn />
    </Filter>
  );

  // eslint-disable-next-line react/prop-types
  const EditRowButton = ({ record }) => (
    <EditButton record={record} onClick={(event) => openEditDialog(event, record)} />
  );

  const renderMeaSpace = (record) => getMeaSpaceNameFromId(record.meaSpace);
  return (
    <>
      <List
        actions={<ListActions />}
        filters={<ListFilter />}
        pagination={false}
        bulkActionButtons={false}
        {...props}
        resource="mea-fraisfixeonly"
      >
        <Datagrid>
          <FunctionField source="meaSpace" label="Espace MEA" render={renderMeaSpace} />
          <TextField source="shopDomain" label="Marchand" />
          <DateField source="startDate" label="Date de début" />
          <DateField source="endDate" label="Date de fin" />
          <TextField source="fixedCosts" label="Frais fixes" />
          <TextField source="comment" label="Commentaire" />
          <EditRowButton />
        </Datagrid>
      </List>
      <MEAFraisFixeOnlyCreateEditDialog
        isDisplayDialog={isDisplayDialog}
        closeDialog={closeDialogAction}
        initialValues={meaFraisFixeOnlyToEdit}
      />
    </>
  );
}

export default MEAFraisFixeOnlyComponent;
