import React, { Component } from 'react';
import {
  Show, TopToolbar, ListButton, EditButton, TabbedShowLayout, Tab, TextField, DateField,
} from 'react-admin';
import { Button } from '@material-ui/core';
import { parse } from 'json2csv';

import requester from '../../tools/requester';
import { PropTypes, ActionsTypes } from '../../tools/types';
import config from '../../config';

import { LayoutTitle } from '../Shared/Layout';

/**
 * PaymentShow
 */
class PaymentShow extends Component {
  static downloadXML(paymentId, xmlString) {
    const now = new Date();
    const objectUrl = window.URL.createObjectURL(new Blob([xmlString]));
    const link = document.createElement('a');

    link.href = objectUrl;
    link.setAttribute('download', `payment_${paymentId}_${now.toISOString()}.xml`);
    document.body.appendChild(link);
    link.click();
  }

  constructor(props) {
    super(props);

    this.state = {
      xmlLoading: false,
      csvLoading: false,
    };

    this.endpoint = config.services.backend.endpoint;
  }

  downloadCSV = (paymentId, csvString) => {
    const prefix = 'data:text/csv;charset=utf-8,';
    let content;

    if (csvString) {
      content = parse(csvString);
    }

    const formatedDate = new Date()
      .toLocaleString()
      .replace(/\//g, '-');

    const a = document.createElement('a');
    a.setAttribute('href', `${prefix}${encodeURIComponent(content)}`);
    a.setAttribute('download', `process_de_fraude_${paymentId}_${formatedDate}.csv`);

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  onXMLGeneration = () => {
    const { id } = this.props;

    this.setState({ xmlLoading: true }, async () => {
      try {
        const result = await requester(`${this.endpoint}/payments/${id}/xml`, {});
        PaymentShow.downloadXML(id, result.data);
        this.setState({ xmlLoading: false });
      } catch (err) {
        this.setState({ error: err.message, xmlLoading: false });
      }
    });
  }

  onFraudProcess = () => {
    const { id } = this.props;

    this.setState({ csvLoading: true }, async () => {
      try {
        const result = await requester(`${this.endpoint}/payments/${id}/csv`, {});
        this.downloadCSV(id, result.data);
        this.setState({ csvLoading: false });
      } catch (err) {
        this.setState({ error: err.message, csvLoading: false });
      }
    });
  }

  render() {
    const { xmlLoading, csvLoading } = this.state;

    return (
      <Show
        actions={<PaymentActions onFraudProcess={this.onFraudProcess} onXMLGeneration={this.onXMLGeneration} loading={xmlLoading || csvLoading} />}
        title={<LayoutTitle source="id" />}
        {...this.props}
      >
        <TabbedShowLayout>
          <Tab label="Résumé">
            <TextField source="id" />
            <DateField source="created" showTime locales="fr-FR" label="Date de création" />
            <TextField source="createdBy" label="Paiement créé par" />
            <DateField source="validatedAt" showTime locales="fr-FR" label="Date de validation" />
            <TextField source="validatedBy" label="Paiement validé par" />
            <TextField source="method" label="Méthode de création" />
            <TextField source="finishedAt" label="Date de fin de la validation (processus terminé)" />
            <TextField source="errorMessage" label="Message d'erreur (si une erreur est apparue lors du payment)" />
          </Tab>
        </TabbedShowLayout>
      </Show>
    );
  }
}

PaymentShow.propTypes = {
  id: PropTypes.string.isRequired,
};

function PaymentActions({
  onXMLGeneration, loading, basePath, data, onFraudProcess,
}) {
  return (
<TopToolbar>
    <Button disabled={loading} onClick={onFraudProcess} color="secondary">Process de fraude</Button>
    <Button disabled={loading} onClick={onXMLGeneration} color="secondary">Générer le XML</Button>
    <ListButton basePath={basePath} />
    <EditButton basePath={basePath} record={data} />
</TopToolbar>
  );
}

PaymentActions.propTypes = ActionsTypes.Show;

export default PaymentShow;
