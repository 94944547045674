import React, { useState } from 'react';

import {
  Button,
  required,
  SimpleForm,
  TextInput,
  useNotify,
} from 'react-admin';

import TenantsViewIcon from '@material-ui/icons/AssignmentInd';
import requester from '../../tools/requester';
import config from '../../config';

function TenantsView() {
  const [isLoading, setIsLoading] = useState(false);
  const [isValidValues, setIsValidValues] = useState(false);
  const [shopId, setShopId] = useState();
  const [tenant, setTenant] = useState();

  const notify = useNotify();

  const onChangeShopId = (event) => setShopId(event.target.value);
  const onChangeTenant = (event) => setTenant(event.target.value);

  const validateValues = () => setIsValidValues(shopId && tenant);

  const onCopyToTenant = async () => {
    setIsLoading(true);

    try {
      const response = await requester(`${config.services.backend.endpoint}/tenants/copy-vouchers`, {
        method: 'POST',
        options: {
          data: { shopId, tenant },
        },
      });

      notify(`${response.data?.nbCopy} code promos copiés sur le tenant ${tenant}`, 'success');
    } catch (e) {
      notify(`${e?.response?.data || e.message}`, 'error');
    }

    setIsLoading(false);
  };

  return (
    <SimpleForm
      toolbar={null}
      validate={validateValues}
    >
      <TextInput
        label="Shop ID"
        source="shopId"
        onChange={onChangeShopId}
        validate={required()}
      />
      <TextInput
        label="Tenant"
        source="tenant"
        onChange={onChangeTenant}
        validate={required()}
      />
      <Button
        disabled={isLoading || !isValidValues}
        variant="contained"
        color="primary"
        size="small"
        onClick={onCopyToTenant}
        label="Copy vouchers to tenant"
      />
    </SimpleForm>
  );
}

export { TenantsView, TenantsViewIcon };
