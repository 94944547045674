import React from 'react';

import {
  Edit,
  TopToolbar,
  TabbedForm,
  FormTab,
  SimpleFormIterator,
  FunctionField,
  ArrayInput,
  BooleanInput,
  SelectInput,
  TextInput,
  required,
  ShowButton,
  ListButton,
  DeleteButton,
  Toolbar,
  SaveButton,
  FormDataConsumer,
  usePermissions,
} from 'react-admin';

import cookie from 'cookie';

// Custom
import { omit } from 'lodash';
import CustomFlag from '../Shared/Layout/CustomFlag';
import { LayoutTitle, SectionTitle, TooltipField } from '../Shared/Layout';

import config from '../../config';
import { ActionsTypes, PropTypes } from '../../tools/types';

const { prismic } = config.services;
const searchUrl = prismic && prismic.shopSearchUrl ? prismic.shopSearchUrl : null;

const renderConditionsEdit = (record) => (
  <a href={`${searchUrl}${record.domain}`} target="_blank" rel="noopener noreferrer">{`${searchUrl}${record.domain}`}</a>
);

const validateJson = (value) => {
  try {
    JSON.parse(value);
  } catch (err) {
    return 'invalid JSON';
  }
  return undefined;
};

const renderUserCountry = (record) => {
  if (record.country) {
    return (
      <CustomFlag isoCode={record.country.iso2a || record.country} />
    );
  }
  return 'Pays non défini';
};

/**
 * ShopEdit
 */
function ShopEdit(props) {
  const { permissions } = usePermissions();
  const ownProps = { ...omit(props, ['hasList', 'hasShow', 'hasEdit', 'hasCreate']) };
  const { location } = props;
  const { access_token: token } = cookie.parse(document.cookie);

  const validateForm = (values) => {
    const errors = {};

    if (values.space.default && values.space.pattern) {
      errors.space = {
        pattern: ['L\'espace par défaut ne doit pas avoir de pattern'],
      };
    }

    if (!values.space.default && !values.space.pattern) {
      errors.space = {
        pattern: ['L\'espace doit obligatoirement avoir un pattern'],
      };
    }

    return errors;
  };
  const transform = (data) => ({
    ...data,
    space: {
      ...data.space,
      pattern: data.space.default ? null : data.space.pattern,
    },
  });

  // Set redirect
  let redirect = 'show';
  if (location.pathname.endsWith('/configs')) {
    redirect = 'show/configs';
  }

  return (
    <Edit
      title={<LayoutTitle />}
      actions={<ShopActions permissions={permissions ? permissions.shops : {}} />}
      transform={transform}
      {...ownProps}
    >
      <TabbedForm
        toolbar={<CustomToolbar permissions={permissions ? permissions.shops : {}} />}
        redirect={redirect}
        {...ownProps}
      >
        <FormTab variant="standard" label="Résumé" validate={validateForm}>
          <TextInput disabled source="id" />

          <FunctionField
            label="Pays"
            render={renderUserCountry}
          />

          <TextInput source="domain" validate={required()} label="Domaine" />

          {/* eslint-disable-next-line react/jsx-boolean-value */}
          <BooleanInput source="space.default" label="Espace par défaut" defaultValue={true} />

          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <TextInput
                source="space.pattern"
                disabled={formData.space.default}
                validate={(value, allValues) => !allValues.space.default && !value && 'L\'espace doit obligatoirement avoir un pattern'}
                format={(inputValue) => (formData.space.default ? null : inputValue)}
                label="Pattern"
                defaultValue={null}
                {...rest}
              />
            )}
          </FormDataConsumer>

          <TextInput source="url" validate={required()} />

          <SectionTitle label="Cashback" />
          <BooleanInput source="cashbackActivated" label="Activé" />
          <TextInput source="cashbackActivatedComment" multiline label="Commentaire" />
          <FunctionField
            label="Editer les conditions:"
            render={renderConditionsEdit}
          />

          <SectionTitle label="Comportement des coupons" />
          <TooltipField title="Activer ou désactiver le testeur de codes promo sur ce site" placement="top-start">
            <SelectInput
              source="voucherAccess"
              choices={[
                { id: 'ACTIVE', name: 'ACTIVE' },
                { id: 'RESTRICTIVE', name: 'RESTRICTIVE' },
                { id: 'OFF', name: 'OFF' },
              ]}
            />
          </TooltipField>
          <TooltipField title="Description du comportement du testeur de code : rechargement de page, message de refus de code…" placement="top-start">
            <SelectInput
              source="voucherBehavior"
              choices={[
                { id: 'REFRESH', name: 'REFRESH' },
                { id: 'INLINE', name: 'INLINE' },
                { id: 'TAB', name: 'TAB' },
              ]}
            />
          </TooltipField>
          <BooleanInput source="alertEnabled" label="Alerte nouveau coupon" />

          <TextInput className="hidden" type="hidden" name="token" source="token" defaultValue={token} readOnly label="" />

        </FormTab>
        <FormTab variant="standard" label="Configs" path="configs">
          <ArrayInput fullWidth source="configs" label="Desktop">
            <SimpleFormIterator fullWidth disableRemove>
              <BooleanInput source="active" label="Active" defaultValue />
              <TextInput source="selectorsInput" label="Selecteurs" multiline fullWidth validate={[required(), validateJson]} />
            </SimpleFormIterator>
          </ArrayInput>
          <ArrayInput fullWidth source="configsMobile" label="Mobile" component="h1">
            <SimpleFormIterator fullWidth disableRemove>
              <BooleanInput source="active" label="Active" defaultValue />
              <TextInput source="selectorsInput" label="Selecteurs" multiline fullWidth validate={[required(), validateJson]} />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab variant="standard" label="Comparateur" path="comparator">
          <ArrayInput fullWidth source="configsComparator" label="Comparateur Configs" component="h1">
            <SimpleFormIterator fullWidth disableRemove>
              <BooleanInput source="active" label="Active" defaultValue />
              <TextInput source="selectorsInput" label="Selecteurs" multiline fullWidth validate={[required(), validateJson]} />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
}

ShopEdit.propTypes = {
  location: PropTypes.object,
};

function ShopActions({ basePath, data }) {
  return (
<TopToolbar>
    <ListButton basePath={basePath} />
    <ShowButton basePath={basePath} record={data} />
</TopToolbar>
  );
}
ShopActions.propTypes = ActionsTypes.Edit;

function CustomToolbar(props) {
  return (
<Toolbar {...props}>
    <SaveButton {...props} />
    {props.permissions.delete && <DeleteButton {...props} />}
</Toolbar>
  );
}
CustomToolbar.propTypes = {
  permissions: PropTypes.object,
};

export default ShopEdit;
