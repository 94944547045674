import React, { useState } from 'react';
import moment from 'moment';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@material-ui/core';
import ActionDelete from '@material-ui/icons/Delete';
import IconCancel from '@material-ui/icons/Cancel';

import {
  Button,
  DateInput,
  NumberInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import { Autocomplete } from '@material-ui/lab';
import { PropTypes } from '../../../tools/types';
import { useBannerContext } from './BannerContext';
import { BANNER_TYPES, formatBannerType } from './bannerTypes';

const GOBONUS_URL = 'https://wanteeed.com/fr/gobonus';

export const BannerModal = ({
  isOpen,
  closeModal,
  availableShops,
  setAvailableShops,
  selectedBanner,
  bannerTypeIsAlreadyUsed,
}) => {
  const [selectBannerTypeIsOpen, setSelectBannerTypeIsOpen] = useState(false);
  const openSelectBannerType = () => {
    setSelectBannerTypeIsOpen(true);
  };
  const closeSelectBannerType = () => {
    setSelectBannerTypeIsOpen(false);
  };
  const [bannerType, setBannerType] = useState(selectedBanner?.bannerType ?? '');
  const [linkText, setLinkText] = useState(selectedBanner?.linkText ?? '');
  const [linkUrl, setLinkUrl] = useState(selectedBanner?.linkUrl ?? GOBONUS_URL);
  const [logoUrl, setLogoUrl] = useState(selectedBanner?.logoUrl ?? '');
  const [startDate, setStartDate] = useState(selectedBanner?.startDate ?? '');
  const [fixedCosts, setFixedCosts] = useState(selectedBanner?.fixedCosts ?? 0);
  const updateStartDate = (event) => {
    const date = moment(event.target.value)
      .hour(0)
      .minute(1)
      .second(0)
      .millisecond(0)
      .toISOString();
    setStartDate(date);
  };
  const [endDate, setEndDate] = useState(selectedBanner?.endDate ?? '');
  const updateEndDate = (event) => {
    const date = moment(event.target.value)
      .hour(23)
      .minute(59)
      .second(0)
      .millisecond(0)
      .toISOString();
    setEndDate(date);
  };

  const [selectShopIsOpen, setSelectShopIsOpen] = useState(false);
  const openSelectShop = async () => {
    setSelectShopIsOpen(true);
  };
  const [errorMessage, setErrorMessage] = useState('');

  const {
    fetchShops, createBanner, updateBanner, deleteBanner, bannerPermissions, loadBannerDataForMonth, countryFilter,
  } = useBannerContext();
  const searchShops = async (event, newInputValue) => {
    const shops = await fetchShops({ input: newInputValue, countryIso2a: countryFilter });
    setAvailableShops(shops.data);
  };
  const selectShopOptionRenderer = (a) => a && `${a.domain.replace('*.', '')} (${a.url}) - ${a.country.iso2a}`;
  const [selectedShop, setSelectedShop] = useState(
    selectedBanner?.shop ?? null,
  );
  const updateShop = (event, newShop) => {
    setSelectedShop(newShop);
    if (!newShop) {
      setLinkUrl(GOBONUS_URL);
      return;
    }

    setLinkUrl(`${GOBONUS_URL}/${newShop.id}`);
  };

  const updateBannerType = (event, newBannerType) => {
    setBannerType(newBannerType);
  };
  const isUpdate = !!selectedBanner;

  const handleSave = async () => {
    if (!bannerPermissions.write) {
      closeModal();

      return;
    }

    const bannerData = {
      startDate,
      endDate,
      bannerType,
      linkText,
      linkUrl,
      logoUrl,
      fixedCosts,
      idShop: selectedShop.id,
    };

    if (moment(endDate).isBefore(startDate)) {
      setErrorMessage('La date de fin doit être postérieure à la date de début');

      return;
    }

    if (bannerTypeIsAlreadyUsed({
      id: selectedBanner?.id, bannerType, startDate, endDate,
    })) {
      setErrorMessage('Ce type de bannière est déjà utilisé pour cette période');

      return;
    }

    try {
      if (isUpdate) {
        await updateBanner({ ...bannerData, id: selectedBanner.id });
      } else {
        await createBanner(bannerData);
      }

      await loadBannerDataForMonth();
      closeModal();
    } catch (error) {
      setErrorMessage('Une erreur est survenue lors de la création / mise à jour de la bannière');
    }
  };

  const handleDelete = async () => {
    try {
      if (bannerPermissions.delete) {
        await deleteBanner(selectedBanner.id);
        await loadBannerDataForMonth();
      }
      closeModal();
    } catch (error) {
      setErrorMessage('Une erreur est survenue lors de la suppression de la bannière');
    }
  };

  return (
    <Dialog fullWidth open={isOpen} onClose={closeModal}>
      <DialogTitle>{`${isUpdate ? 'Modifier' : 'Créer'} une réservation`}</DialogTitle>
      <DialogContent>
        <SimpleForm toolbar={null}>
          <Autocomplete
            defaultValue={bannerType}
            id="autocomplete-banner-type"
            style={{ width: 'auto', marginBottom: '20px' }}
            open={selectBannerTypeIsOpen}
            onOpen={openSelectBannerType}
            onClose={closeSelectBannerType}
            getOptionLabel={formatBannerType}
            options={BANNER_TYPES[countryFilter]}
            onChange={updateBannerType}
            renderInput={(params) => (
              <TextField {...params} label="Type de bannière" />
            )}
          />
          <Autocomplete
            defaultValue={selectedShop}
            id="autocomplete-shop"
            style={{ width: 'auto', marginBottom: '20px' }}
            open={selectShopIsOpen}
            onOpen={openSelectShop}
            onClose={() => {
              setSelectShopIsOpen(false);
            }}
            getOptionLabel={selectShopOptionRenderer}
            options={availableShops}
            onInputChange={searchShops}
            onChange={updateShop}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Shop"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: <>{params.InputProps.endAdornment}</>,
                }}
              />
            )}
          />
          {selectedShop && (
            <TextField
              label="Shop ID"
              value={selectedShop.id}
              disabled
              fullWidth
            />
          )}
          <TextInput
            label="Texte du lien"
            source="linkText"
            initialValue={linkText}
            value={linkText}
            onChange={(e) => {
              setLinkText(e.target.value);
            }}
            fullWidth
          />
          <TextInput
            label="URL du lien"
            source="linkUrl"
            initialValue={linkUrl}
            value={linkUrl}
            onChange={(e) => setLinkUrl(e.target.value)}
            fullWidth
            disabled
          />
          <TextInput
            label="URL du logo"
            source="logoUrl"
            initialValue={logoUrl}
            value={logoUrl}
            onChange={(e) => setLogoUrl(e.target.value)}
            fullWidth
          />
          {logoUrl && (
            <img src={logoUrl} alt="logo" style={{ width: '100px' }} />
          )}
          <DateInput
            source="startDate"
            label="Valable du"
            initialValue={startDate}
            onChange={updateStartDate}
            fullWidth
          />
          <DateInput
            source="endDate"
            label="Valable au"
            initialValue={endDate}
            onChange={updateEndDate}
            fullWidth
          />
          <NumberInput
            source="fixedCosts"
            label="Frais fixes"
            initialValue={fixedCosts}
            onChange={(e) => setFixedCosts(e.target.value)}
            fullWidth
          />
        </SimpleForm>
        {errorMessage && <Typography style={{ color: 'red' }}>{errorMessage}</Typography>}
      </DialogContent>
      <DialogActions>
            {isUpdate && bannerPermissions.delete
              && (
                <Button label="Remove" color="default" onClick={handleDelete} style={{ color: 'red', marginRight: 'auto' }}>
                  <ActionDelete />
                </Button>
              )}
            <Button
              label={isUpdate ? 'Update' : 'Save'}
              color="primary"
              disabled={!bannerPermissions.write}
              onClick={handleSave}
            />
            <Button label="ra.action.cancel" color="secondary" onClick={closeModal}>
              <IconCancel />
            </Button>
      </DialogActions>
    </Dialog>
  );
};

BannerModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  availableShops: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    domain: PropTypes.string,
    country: PropTypes.shape({
      iso2a: PropTypes.string,
    }),
    url: PropTypes.string,
  })).isRequired,
  setAvailableShops: PropTypes.func.isRequired,
  selectedBanner: PropTypes.shape({
    id: PropTypes.string.isRequired,
    bannerType: PropTypes.string.isRequired,
    linkText: PropTypes.string.isRequired,
    linkUrl: PropTypes.string.isRequired,
    logoUrl: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    fixedCosts: PropTypes.number.isRequired,
    shop: PropTypes.shape({
      id: PropTypes.string,
      domain: PropTypes.string,
      country: PropTypes.shape({
        iso2a: PropTypes.string,
      }),
      url: PropTypes.string,
    }),
  }),
  bannerTypeIsAlreadyUsed: PropTypes.func.isRequired,
};
