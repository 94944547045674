import React from 'react';
import { Admin, Resource } from 'react-admin';
import { Route } from 'react-router-dom';

// Tools
import provider from './dataProvider';

// *
// Components
import LoginPage from './components/Login/LoginPage';
import Dashboard from './components/Dashboard';
import NotFound from './components/NotFound';

import ShopEdit from './components/Shop/Edit';
import ShopShow from './components/Shop/Show';
import ShopCreate from './components/Shop/Create';
import { ShopList, ShopListIcon } from './components/Shop';

import UserEdit from './components/User/Edit';
import UserShow from './components/User/Show';
import { UserList, UserListIcon } from './components/User';

import BonusPatternEdit from './components/BonusPattern/Edit';
import BonusPatternCreate from './components/BonusPattern/Create';
import { BonusPatternList, BonusPatternListIcon } from './components/BonusPattern';

import ProgramEdit from './components/Program/Edit';
import ProgramShow from './components/Program/Show';
import { ProgramList, ProgramListIcon } from './components/Program';

import CashbackEdit from './components/Cashback/Edit';

import BillEdit from './components/Bill/Edit';
import BillShow from './components/Bill/Show';
import { BillList, BillListIcon } from './components/Bill';
import BillReconciliation from './components/Reconciliation';

import TransactionShow from './components/Transaction/Show';
import { TransactionList, TransactionListIcon } from './components/Transaction';

import PaymentShow from './components/Payment/Show';
import PaymentEdit from './components/Payment/Edit';
import { PaymentList, PaymentListIcon } from './components/Payment';

import VoucherEdit from './components/Voucher/Edit';
import VoucherCreate from './components/Voucher/Create';

import BlacklistEdit from './components/Blacklist/Edit';
import BlacklistCreate from './components/Blacklist/Create';

import ClaimCreate from './components/Claim/Create';
import ClaimShow from './components/Claim/Show';
import ClaimEdit from './components/Claim/Edit';
import { ClaimList, ClaimListIcon } from './components/Claim';

import authProvider from './tools/authProvider';
import SharePolicyCreate from './components/SharePolicy/Create';

import { MEAMenu, MEAListIcon } from './components/MEA';
import { ExportView, ExportViewIcon } from './components/Export';
import { TenantsView, TenantsViewIcon } from './components/Tenants';
import {
  InquiryList,
  InquiryListIcon,
} from './components/Inquiry';
import InquiryEdit from './components/Inquiry/Edit';
import InquiryShow from './components/Inquiry/Show';
import {
  InquiryImportDomainList,
  InquiryImportDomainListIcon,
} from './components/InquiryImportDomain';
import { InquiriesImportView, InquiriesImportIcon } from './components/InquiriesImport';
import { EmailCampaignView, EmailCampaignIcon } from './components/EmailCampaign';
import ExtensionBannerList from './components/ExtensionBanner/extensionBannerList';
import InquiryImportDomainEdit from './components/InquiryImportDomain/Edit';
import InquiryImportDomainCreate from './components/InquiryImportDomain/Create';
import CashbackAutodisplayPatternEdit from './components/CashbackAutodisplayPattern/Edit';
import CashbackAutodisplayPatternCreate from './components/CashbackAutodisplayPattern/Create';

const customRoutes = [
  // eslint-disable-next-line react/jsx-filename-extension
  <Route exact path="/bills/reconciliate" component={BillReconciliation} />,
];

/**
 * Authenticated routes
 */
const authRoutes = (
  <Admin
    title="Wanteeed Admin"
    loginPage={LoginPage}
    dashboard={Dashboard}
    catchAll={NotFound}
    customRoutes={customRoutes}
    dataProvider={provider}
    authProvider={authProvider}
  >
    {(permissions) => [
      permissions.shops.read
        ? (
          <Resource
            name="shops"
            options={{ label: 'Boutiques' }}
            list={ShopList}
            show={ShopShow}
            edit={permissions.shops.write ? ShopEdit : null}
            create={permissions.shops.write ? ShopCreate : null}
            icon={ShopListIcon}
          />
        )
        : null,
      permissions.programs.cashbacks.read
        ? (
          <Resource
            name="programs/cashbacks"
            edit={permissions.programs.cashbacks.write ? CashbackEdit : null}
          />
        )
        : null,
      permissions.shops.cashbacks.write
        ? (
          <Resource
            name="cashback-autodisplay-patterns"
            basePath="/cashback-autodisplay-patterns"
            create={permissions.shops.cashbacks.write ? CashbackAutodisplayPatternCreate : null}
            edit={permissions.shops.cashbacks.write ? CashbackAutodisplayPatternEdit : null}
          />
        )
        : null,
      permissions.programs.read
        ? (
          <Resource
            name="programs"
            options={{ label: 'Programmes' }}
            list={ProgramList}
            show={ProgramShow}
            edit={permissions.programs.write ? ProgramEdit : null}
            icon={ProgramListIcon}
          />
        )
        : null,
      permissions.programs.read
        ? (
          <Resource
            name="share-policy"
            options={{ label: 'Taux de reversement' }}
            create={permissions.programs.write ? SharePolicyCreate : null}
          />
        )
        : null,
      permissions.transactions.read
        ? (
          <Resource
            name="transactions"
            options={{ label: 'Transactions' }}
            list={TransactionList}
            show={TransactionShow}
            icon={TransactionListIcon}
          />
        )
        : null,
      permissions.bills.read
        ? (
          <Resource
            name="bills"
            options={{ label: 'Factures' }}
            list={BillList}
            show={BillShow}
            icon={BillListIcon}
            edit={permissions.bills.write ? BillEdit : null}
          />
        )
        : null,
      permissions.payments.read
        ? (
          <Resource
            name="payments"
            options={{ label: 'Virements' }}
            list={PaymentList}
            show={PaymentShow}
            edit={permissions.payments.write ? PaymentEdit : null}
            icon={PaymentListIcon}
          />
        )
        : null,
      permissions.users.read
        ? (
          <Resource
            name="users"
            options={{ label: 'Utilisateurs' }}
            list={UserList}
            show={UserShow}
            edit={permissions.users.write ? UserEdit : null}
            icon={UserListIcon}
          />
        )
        : null,
      permissions['bonus-patterns'].read
        ? (
          <Resource
            name="bonus-patterns"
            options={{ label: 'Popup bonus' }}
            list={BonusPatternList}
            edit={permissions['bonus-patterns'].write ? BonusPatternEdit : null}
            create={permissions['bonus-patterns'].write ? BonusPatternCreate : null}
            icon={BonusPatternListIcon}
          />
        )
        : null,
      permissions.claims.read
        ? (
          <Resource
            name="claims"
            options={{ label: 'Réclamations' }}
            create={permissions.claims.write ? ClaimCreate : null}
            edit={ClaimEdit}
            show={ClaimShow}
            list={ClaimList}
            icon={ClaimListIcon}
          />
        )
        : null,
      permissions.vouchers.read
        ? (
          <Resource
            name="vouchers"
            create={permissions.vouchers.write ? VoucherCreate : null}
            edit={permissions.vouchers.write ? VoucherEdit : null}
          />
        )
        : null,
      permissions.blacklists.read
        ? (
          <Resource
            name="blacklists"
            create={permissions.blacklists.write ? BlacklistCreate : null}
            edit={permissions.blacklists.write ? BlacklistEdit : null}
          />
        )
        : null,
      permissions.mea.read
        ? (
          <Resource
            name="mea"
            options={{ label: 'MEA' }}
            list={MEAMenu}
            icon={MEAListIcon}
          />
        ) : null,
      <Resource
        name="mea-fraisfixeonly"
      />,
      permissions?.export?.read
        ? (
          <Resource
            name="export"
            options={{ label: 'Exports' }}
            list={ExportView}
            icon={ExportViewIcon}
          />
        ) : null,
      <Resource
        name="shops/cashbacks"
      />,
      permissions?.tenants?.write
        ? (
          <Resource
            name="tenant"
            options={{ label: 'Tenant' }}
            list={TenantsView}
            icon={TenantsViewIcon}
          />
        ) : null,
      permissions?.extensionBanner?.write
        ? (
          <Resource
            name="extension-banner"
            options={{ label: 'Extension banner' }}
            list={ExtensionBannerList}
            icon={TenantsViewIcon}
          />
        ) : null,
      permissions?.inquiries?.read
        ? (
         <Resource
           name="inquiries"
           options={{ label: 'Récla Strackr' }}
           list={InquiryList}
           edit={InquiryEdit}
           show={InquiryShow}
           icon={InquiryListIcon}
         />
        ) : null,
      permissions?.['inquiry-import-domains']?.read
        ? (
         <Resource
           name="inquiry-import-domains"
           options={{ label: 'Domaines récla auto' }}
           list={InquiryImportDomainList}
           icon={InquiryImportDomainListIcon}
           edit={InquiryImportDomainEdit}
           create={permissions['inquiry-import-domains'].write ? InquiryImportDomainCreate : null}
         />
        ) : null,
      permissions?.inquiriesImport?.read
        ? (
          <Resource
            name="inquiries-import"
            options={{ label: 'Récla auto' }}
            list={InquiriesImportView}
            icon={InquiriesImportIcon}
          />
        ) : null,
      permissions?.emailing?.write
        ? (
            <Resource
              name="emailing-campaign"
              options={{ label: 'Campagne email' }}
              list={EmailCampaignView}
              icon={EmailCampaignIcon}
            />
        ) : null,
    ]}
  </Admin>
);

export default authRoutes;
