import React, { PureComponent } from 'react';
import {
  SelectInput,
  Filter,
} from 'react-admin';

import moment from 'moment';

import { PropTypes } from '../../../tools/types';
import requester from '../../../tools/requester';
import config from '../../../config';

class MEAFilterComponent extends PureComponent {
  constructor() {
    super();
    this.state = {};
  }

  async componentDidMount() {
    const monthYear = moment().format('MM-YYYY');
    const countries = await this.fetchCountries();
    const defaultCountry = countries.find((c) => c.name === 'FR');
    this.reloadData({
      monthYear,
      country: defaultCountry.id,
      countries,
    });
    this.setState({
      countries,
      country: defaultCountry.id,
      countryISO2A: defaultCountry.name,
      monthYear,
      monthYears: this.getMonthYears(),
    });
  }

  fetchData = async (date, country) => {
    const countryQueryString = country ? `&country=${country}` : '';
    const result = await requester(`${config.services.backend.endpoint}/homepage-slides?date=${date}${countryQueryString}`, {});
    return result.data;
  }

  fetchCountries = async () => {
    const response = await requester(`${config.services.backend.endpoint}/countries`, {});
    return response.data.map((r) => ({ id: r.id, name: r.iso2a }));
  }

  getMonthYears = () => {
    const startYear = 2021;
    const endYear = moment().year() + 5;
    const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
    const monthYears = [];
    for (let i = startYear; i < endYear; i++) {
      monthYears.push(...months.map((m) => ({ id: `${m}-${i}`, name: `${m}-${i}` })));
    }

    return monthYears;
  }

  handleDateChange = (event) => {
    this.reloadData({ monthYear: event.target.value });
  };

  handleCountryChange = (event) => {
    this.reloadData({ country: event.target.value });
  };

  reloadData = async (filters) => {
    const { monthYear: monthyearState, country: countryState } = this.state;

    const monthYear = filters?.monthYear || monthyearState;
    const country = filters?.country || countryState;
    const countries = filters?.countries || this.state.countries;
    this.setState({ country, monthYear });
    this.props.onChange({ monthYear, country, countryISO2A: countries.find((c) => c.id === country).name });
  }

  render() {
    const {
      monthYears,
      monthYear,
      countries,
      country,
    } = this.state;

    return (
      <Filter {...this.props} setFilters={() => { }}>
        <SelectInput source="monthYear" choices={monthYears} initialValue={monthYear} alwaysOn allowEmpty={false} onChange={this.handleDateChange} />
        <SelectInput source="country" choices={countries} initialValue={country} alwaysOn allowEmpty={false} onChange={this.handleCountryChange} />
      </Filter>
    );
  }
}

MEAFilterComponent.propTypes = {
  onChange: PropTypes.func,
};

export default MEAFilterComponent;
