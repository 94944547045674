import React, { Component } from 'react';
import {
  List, Datagrid, DateField, TopToolbar, ExportButton, TextField, ShowButton,
} from 'react-admin';
import PaymentListIcon from '@material-ui/icons/ArrowForward';
import { Button, Typography } from '@material-ui/core';
import { parse } from 'json2csv';

import config from '../../config';
import requester from '../../tools/requester';
import { OtherTypes, PropTypes } from '../../tools/types';
// Custom stuff
import { IdField } from '../Shared/Layout';
import SimplePagination from '../Shared/Pagination/SimplePagination';

/**
 * PaymentList
 */
class PaymentList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      createLoading: false,
      createError: false,
    };

    this.config = config;
    this.endpoint = config.services.backend.endpoint;
  }

  /**
   * Create a text file from array and trigger download
   */
  downloadFile = (data) => {
    const prefix = 'data:text/csv;charset=utf-8,';
    const content = parse(data);
    const formatedDate = new Date()
      .toLocaleDateString()
      .replace(/\//g, '-');

    const a = document.createElement('a');
    a.setAttribute('href', `${prefix}${encodeURIComponent(content)}`);
    a.setAttribute('download', `discarded_usersId_${formatedDate}.csv`);

    document.body.appendChild(a);
    a.click();
  }

  /**
   * Create a bill
   */
  onCreatePayment = async () => {
    const { basePath, history } = this.props;

    this.setState({ createLoading: true });

    try {
      const result = await requester(`${this.endpoint}/payments`, { method: 'post' });
      const { data } = result;
      if (data.discarded.length > 0) {
        const discardedTransactions = data.discarded.reduce((acc, current) => acc.concat(current.transactionIds), []);
        const deduplicatedUsersIds = [];

        data.discarded.forEach(({ reason, user }) => {
          if (!deduplicatedUsersIds.some((trans) => trans.user === user.id)) {
            deduplicatedUsersIds.push({
              user: user.id,
              reason,
            });
          }
        });

        const message = `${discardedTransactions.length} transactions have been ignored for ${deduplicatedUsersIds.length} users.`;

        // Format data to file and trigger download
        this.downloadFile(deduplicatedUsersIds);

        alert(message); // eslint-disable-line no-alert

        console.log(message, discardedTransactions); // eslint-disable-line no-console

        console.log('The following users have been ignored :'); // eslint-disable-line no-console
        console.table(deduplicatedUsersIds); // eslint-disable-line no-console
      }
      history.push(`${basePath}/${data.created.id}/show`);
    } catch (err) {
      const message = err.response ? err.response.data : err.message;
      this.setState({ createError: message, createLoading: false }, () => {
        setTimeout(() => {
          this.setState({ createError: undefined });
        }, 1000);
      });
    }
  }

  render() {
    const { createLoading, createError } = this.state;

    // Handle unexepted create error
    if (createError) {
      alert(createError); // eslint-disable-line no-alert
    }

    const actions = (
      <PaymentAction
        onCreatePayment={this.onCreatePayment}
        loading={createLoading}
        error={createError}
      />
    );

    return (
      <List {...this.props} actions={actions} perPage={20} pagination={<SimplePagination />}>
        <Datagrid>
          <IdField label="id" sortable={false} />
          <TextField source="method" sortable={false} label="Méthode" />
          <DateField source="created" locales="fr-FR" label="Créé le" sortable={false} />
          <DateField source="validatedAt" locales="fr-FR" label="Validé le" sortable={false} />
          <ShowButton />
        </Datagrid>
      </List>
    );
  }
}

PaymentList.propTypes = {
  basePath: PropTypes.string,
  history: PropTypes.object,
};

function PaymentAction({
  onCreatePayment, loading, error, resource, exporter, filterValues, currentSort,
}) {
  return (
<TopToolbar>
      {error && (
        <Typography color="error">{error}</Typography>
      )}
      <Button disabled={loading} onClick={onCreatePayment} color="secondary">Créer un paiement</Button>
      <ExportButton
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
      />
</TopToolbar>
  );
}

PaymentAction.propTypes = OtherTypes.ExportButton;

export { PaymentList, PaymentListIcon };
