import React from 'react';
import {
  Show,
  TopToolbar,
  TabbedShowLayout,
  Tab,
  DateField,
  ListButton,
  Datagrid,
  TextField,
  ArrayField,
  EditButton,
  FunctionField,
  BooleanField,
  usePermissions,
  ReferenceManyField,
  Pagination,
} from 'react-admin';
import { Link } from 'react-router-dom';

import VerifiedIcon from '@material-ui/icons/VerifiedUser';
import {
  Button, Checkbox,
} from '@material-ui/core';

// Custom
import CustomFlag from '../Shared/Layout/CustomFlag';
import { SectionTitle, LayoutTitle, ShowLink } from '../Shared/Layout';
import { ActionsTypes, PropTypes } from '../../tools/types';
import UserName from './Shared';
import config from '../../config';
import { ValidateMultipleTransactionsButton } from './ValidateMultipleTransactions/ValidateMultipleTransactions';

function UserTransactionPagination(props) {
  return <Pagination {...props} rowsPerPageOptions={[10, 25, 50, 100]} />;
}

const renderStatus = (record) => {
  let status = 'En attente';
  const history = record.history.edges ? record.history.edges.node[0] : record.history;

  if (history.declineSource != null) {
    status = 'Refusé';
  } else if (history.paidToUser) {
    status = 'Payé';
  } else if (history.paymentAsked) {
    status = 'Demandé';
  } else if (history.approved && record.bill && record.bill.paid) {
    status = 'Confirmé';
  }

  return status;
};

const renderTransactionUserValue = (record) => {
  const currency = config.currencies
    .find((item) => item.id.toLowerCase() === record.currency.toLowerCase());
  const value = record.history.edges ? record.history.edges.node[0].userValue : record.history.userValue;

  return `${value} ${currency ? currency.name : config.currencies[0].name}`;
};

const postRowStyle = (record) => {
  if (record) {
    return {
      backgroundColor: record.isOverrided ? '#f2f2f2' : undefined,
      fontStyle: record.isOverrided ? 'italic' : 'normal',
    };
  }

  return {};
};

const renderUserCountry = (record) => {
  if (record.country) {
    return (
      <CustomFlag isoCode={record.country.iso2a} />
    );
  }
  return 'Pays non défini';
};

const isClaimCreationDisabled = (record) => !!record.bill
  || record.type === 'CLAIM'
  || !!record.transactionClaimLink
  || !!record.declineSource
  || !!record.paidToUser
  || record.claim !== null
  || record.userValue === 0;

/**
 * UserShow
 */
function UserShow(props) {
  const { permissions } = usePermissions();
  const [selectedTransactions, setSelectedTransactions] = React.useState([]);
  const updateSelectedTransactions = (transaction) => () => {
    if (selectedTransactions.find((t) => t.id === transaction.id)) {
      return setSelectedTransactions(selectedTransactions.filter((t) => t.id !== transaction.id));
    }

    return setSelectedTransactions([...selectedTransactions, transaction]);
  };

  return (
    <Show actions={<UserActions selectedTransactions={selectedTransactions} />} title={<LayoutTitle source="email" />} {...props}>
      <TabbedShowLayout>
        <Tab label="Résumé">
          <TextField source="id" />

          <FunctionField
            label="Pays"
            render={renderUserCountry}
          />

          <UserName label="Nom" />
          <UserEmail label="Email" />

          <DateField source="birthdate" label="Anniversaire" locales="fr-FR" />
          <DateField source="created" label="Créé le" showTime locales="fr-FR" />

          <BooleanField label="Paiements bloqués" source="blockPayment.hasBlockedPayment" />
          <TextField source="fraudComment" label="Commentaire Fraude" />

          <SectionTitle label="Coordonnées bancaires" />
          <TextField source="bic" label="BIC" />
          <TextField source="iban" label="IBAN" />

        </Tab>

        <Tab label="Transactions" path="Transactions">
          <ReferenceManyField {...props} addLabel={false} reference="transactions" target="userId" perPage={100} pagination={<UserTransactionPagination />}>
            <Datagrid rowStyle={postRowStyle}>
              <FunctionField
                render={(record) => (
                <Checkbox disabled={isClaimCreationDisabled(record)} onChange={updateSelectedTransactions(record)} checked={selectedTransactions.find((t) => t === record.id)} />
                )}
              />
              <FunctionField
                label="Id"
                render={(record) => (
                  <ShowLink
                    style={{ color: record.isOverrided ? '#979797' : undefined }}
                    record={record}
                    basePath="transactions"
                    source="id"
                    title="id"
                  />
                )}
              />
              <FunctionField
                label="Shop"
                render={(record) => (
                  <ShowLink
                    style={{ color: record.isOverrided ? '#979797' : undefined }}
                    record={record}
                    basePath="shops"
                    source="shop.id"
                    title="shop.domain"
                  />
                )}
              />
              <FunctionField
                label="type"
                render={(record) => (
                  <TextField
                    style={{ color: record.isOverrided ? '#979797' : undefined }}
                    record={record}
                    source="type"
                  />
                )}
              />
              <FunctionField
                label="Status"
                render={(record) => (
                  <FunctionField
                    style={{ color: record.isOverrided ? '#979797' : undefined }}
                    record={record}
                    render={renderStatus}
                  />
                )}
              />
              <FunctionField
                label="Montant"
                render={(record) => (
                  <FunctionField
                    style={{ color: record.isOverrided ? '#979797' : undefined }}
                    record={record}
                    render={renderTransactionUserValue}
                  />
                )}
              />
              <FunctionField
                label="Date"
                render={(record) => (
                  <DateField
                    style={{ color: record.isOverrided ? '#979797' : undefined }}
                    record={record}
                    source="date"
                  />
                )}
              />
              <FunctionField
                label="Programme"
                render={(record) => (
                  <ProgramLink
                    style={{ color: record.isOverrided ? '#979797' : undefined }}
                    record={record}
                    source="program"
                  />
                )}
              />
              <FunctionField
                label="Facture"
                render={(record) => (
                  <BillLink
                    style={{ color: record.isOverrided ? '#979797' : undefined }}
                    record={record}
                    source="bill"
                  />
                )}
              />

              <FunctionField
                label="Réclamation"
                render={(record) => {
                  if (record.claim !== null) {
                    return (<ShowClaimLink record={record} label="Réclamation" permissions={permissions} />);
                  }
                  return (<CreateClaimLink record={record} label="Réclamation" permissions={permissions} />);
                }}
              />

            </Datagrid>
          </ReferenceManyField>
        </Tab>

        <Tab label="Relations" path="relations">
          <UserReferrer label="Parain" />
          <ArrayField source="referrered.edges.node" label="filleuls">
            <Datagrid>
              <TextField source="id" />
              <UserName label="nom" />
              <UserEmail label="Email" />
            </Datagrid>
          </ArrayField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
}

function UserActions({ basePath, data, selectedTransactions }) {
  const { permissions } = usePermissions();

  return (
  <TopToolbar style={{ display: 'flex', alignItems: 'center' }}>
      <ListButton basePath={basePath} />
      <EditButton basePath={basePath} record={data} />
      {permissions?.transactions.write && <ValidateMultipleTransactionsButton selectedTransactions={selectedTransactions} />}
  </TopToolbar>
  );
}

UserActions.propTypes = ActionsTypes.Show;

function UserEmail({ record }) {
  return (
<span>
    {record.emailVerified && <VerifiedIcon style={{ fontSize: '16px' }} />}
    {record.email}
</span>
  );
}

UserEmail.defaultProps = {
  addLabel: true, // eslint-disable-line react/default-props-match-prop-types
};

UserEmail.propTypes = {
  record: PropTypes.object,
};

function UserReferrer({ record }) {
  return (
<span>
    {record.referrer
      && <a href={`#/users/${record.referrer.id}/show`} target="_blank" rel="noopener noreferrer">{record.referrer.email}</a>}
</span>
  );
}

UserReferrer.defaultProps = {
  addLabel: true, // eslint-disable-line react/default-props-match-prop-types
};

UserReferrer.propTypes = {
  record: PropTypes.object,
};

function ProgramLink({ record, ...props }) {
  return (
<span>
    {record.program
      && <a {...props} href={`#/programs/${record.program.id}/show`} target="_blank" rel="noopener noreferrer">{record.program.name}</a>}
</span>
  );
}

ProgramLink.propTypes = {
  record: PropTypes.object,
};

function BillLink({ record, ...props }) {
  return (
<span>
    {record.bill
      && <a {...props} href={`#/bills/${record.bill.id}/show`} target="_blank" rel="noopener noreferrer" title={record.bill.reference || ''}>{record.bill.id}</a>}
</span>
  );
}

BillLink.propTypes = {
  record: PropTypes.object,
};

const resolveDisabledButtonTitle = (record) => {
  if (record.type === 'CLAIM') {
    return 'Cette transaction est déjà une réclamation';
  }
  if (record.paidToUser) {
    return 'La transaction a déjà été payée';
  }
  if (record.bill) {
    return "La transaction a été validée par la plateforme d'affiliation";
  }
  if (record.transactionClaimLink) {
    return 'La transaction est déjà liée à une réclamation';
  }
  if (record.declineSource) {
    return `La transaction a été refusée. Origine du refus: ${record.declineSource}`;
  }
  if (record.userValue === 0) {
    return 'Impossible de créer une réclamation pour une transaction dont le montant est à 0';
  }

  return 'Impossible de créer une réclamation';
};

function CreateClaimLink({ record, permissions }) {
  const {
    id, userId, orderId, shop, userValue, clickDate,
  } = record;

  const isDisabled = isClaimCreationDisabled(record);

  return (
    <span>
      {permissions.claims.write
        && (
          <div style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }} title={isDisabled ? resolveDisabledButtonTitle(record) : ''}>
            <Button
              component={Link}
              to={{
                pathname: '/claims/create',
                state: {
                  record: {
                    idTransaction: id,
                    idUser: userId,
                    amount: userValue,
                    referenceOrderNumber: orderId,
                    shop,
                    orderDate: clickDate,
                  },
                },
              }}
              size="small"
              color="primary"
              disabled={isDisabled}
            >
              Créer une réclamation
            </Button>
          </div>
        )}
    </span>
  );
}

function ShowClaimLink({ record, permissions }) {
  const { claim } = record;
  const claimId = claim ? claim.id : null;

  return (
    <span>
      {claimId && permissions.claims.read
        && (
          <Button
            component={Link}
            to={{
              pathname: `/claims/${claimId}/show`,
            }}
            size="small"
            color="secondary"
          >
            Voir la réclamation
          </Button>
        )}
    </span>
  );
}

CreateClaimLink.propTypes = {
  record: PropTypes.object,
  permissions: PropTypes.object,
};

ShowClaimLink.propTypes = {
  record: PropTypes.object,
  permissions: PropTypes.object,
};

export default UserShow;
