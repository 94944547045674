import React from 'react';
import {
  SimpleForm,
  TextInput,
  useRefresh,
} from 'react-admin';

import {
  Box, Button, Dialog, DialogTitle, DialogContent, DialogContentText,
} from '@material-ui/core';

// Custom
import { PropTypes } from '../../../tools/types';
import config from '../../../config';
import requester from '../../../tools/requester';
import { ClaimHandler } from './ClaimHandler';

export const ValidateMultipleTransactionsButton = ({ selectedTransactions }) => {
  const disabled = selectedTransactions.length === 0;
  const [isOpen, setIsOpen] = React.useState(false);
  const closeDialog = () => setIsOpen(false);

  const [claimEmail, setClaimEmail] = React.useState('');
  const updateClaimEmail = (e) => setClaimEmail(e.target.value);
  const [orderEmail, setOrderEmail] = React.useState('');
  const updateOrderEmail = (e) => setOrderEmail(e.target.value);

  const [orderNumber, setOrderNumber] = React.useState('');
  const updateOrderNumber = (e) => setOrderNumber(e.target.value);
  const [comment, setComment] = React.useState('');
  const updateComment = (e) => setComment(e.target.value);

  const [validationResults, setValidationResults] = React.useState(null);

  const createAndValidateClaims = async () => {
    const asyncRequester = async (...args) => requester(...args);
    const claimHandler = new ClaimHandler({ requester: asyncRequester, claimsEndpoint: `${config.services.backend.endpoint}/claims` });

    const results = await claimHandler.createAndValidateMultipleClaims(selectedTransactions.map((transaction) => ({
      idTransaction: transaction.id,
      idUser: transaction.userId,
      amount: transaction.userValue,
      shop: transaction.shop,
      orderDate: transaction.date,
      type: 'WAIT_NOT_VALID',
      attachmentsProvided: true,
      claimEmail,
      orderEmail: orderEmail || claimEmail,
      referenceOrderNumber: orderNumber,
      content: comment,
    })));
    setValidationResults(results);
  };

  return (
     <>
      <Button color="primary" disabled={disabled} onClick={setIsOpen}>Valider plusieurs transactions</Button>
      {isOpen && (
        <Dialog
          open={isOpen}
          fullWidth
        >
          {!validationResults && (
            <ClaimDialogForm
              selectedTransactions={selectedTransactions}
              claimEmail={claimEmail}
              orderEmail={orderEmail}
              orderNumber={orderNumber}
              updateClaimEmail={updateClaimEmail}
              updateOrderEmail={updateOrderEmail}
              updateOrderNumber={updateOrderNumber}
              updateComment={updateComment}
              closeDialog={closeDialog}
              createAndValidateClaims={createAndValidateClaims}
            />
          )}
          {!!validationResults && <ClaimDialogResult validationResults={validationResults} closeDialog={closeDialog} />}
        </Dialog>
      )}
     </>
  );
};

ValidateMultipleTransactionsButton.propTypes = {
  selectedTransactions: PropTypes.array,
};

const ClaimDialogForm = ({
  selectedTransactions,
  claimEmail,
  orderEmail,
  orderNumber,
  updateClaimEmail,
  updateOrderEmail,
  updateOrderNumber,
  updateComment,
  closeDialog,
  createAndValidateClaims,
}) => (
      <>
        <DialogTitle>
          {`Vous êtes sur le point de valider ${selectedTransactions.length} transactions`}
        </DialogTitle>
          <DialogContent>
            <DialogContentText>Pour chacune de ces transactions, une &quot;claim&quot; va être créée et validée.</DialogContentText>
            <SimpleForm
              toolbar={false}
              initialValues={{
                claimEmail,
                orderEmail,
                orderNumber,
              }}
            >
              <TextInput type="email" label="email de contact" required source="claimEmail" onChange={updateClaimEmail} />
              <TextInput type="email" label="emai de commande (si différent)" source="orderEmail" onChange={updateOrderEmail} />
              <TextInput label="numéro de commande" source="orderNumber" onChange={updateOrderNumber} />
              <TextInput label="commentaire" source="comment" onChange={updateComment} />
            </SimpleForm>
            <Box display="flex" justifyContent="flex-end" py={1}>
              <Button color="default" type="button" onClick={closeDialog}>
                Annuler
              </Button>
              <Button
                color="primary"
                type="submit"
                disabled={false}
                onClick={createAndValidateClaims}
              >
                Valider les transactions
              </Button>
            </Box>
          </DialogContent>
      </>
);

ClaimDialogForm.propTypes = {
  selectedTransactions: PropTypes.array,
  claimEmail: PropTypes.string,
  orderEmail: PropTypes.string,
  orderNumber: PropTypes.string,
  updateClaimEmail: PropTypes.func,
  updateOrderEmail: PropTypes.func,
  updateOrderNumber: PropTypes.func,
  updateComment: PropTypes.func,
  closeDialog: PropTypes.func,
  createAndValidateClaims: PropTypes.func,
};

const ClaimDialogResult = ({ validationResults, closeDialog }) => {
  const refresh = useRefresh();

  const close = () => {
    closeDialog();
    refresh();
  };
  const { validatedClaims, failedClaimCreations, failedClaimValidations } = validationResults;

  return (
      <>
      <DialogTitle>{`${validatedClaims.length} claims créées et validées avec succès`}</DialogTitle>
      {failedClaimCreations.length > 0 && (
       <>
        <DialogTitle>{`${failedClaimCreations.length} échecs de création de claim sur les transactions suivantes :`}</DialogTitle>
        <DialogContent>
        {failedClaimCreations.map((creationFailure) => (
          <DialogContentText>{creationFailure.transactionId}</DialogContentText>))}
        </DialogContent>
       </>
      )}
      {failedClaimValidations.length > 0 && (
        <>
          <DialogTitle>{`${failedClaimValidations.length} claims créées mais non validées sur les transactions suivantes :`}</DialogTitle>
          <DialogContent>
          {failedClaimValidations.map((validationFailure) => (
            <DialogContentText>{validationFailure.transactionId}</DialogContentText>))}
          </DialogContent>
        </>
      )}
      <Box display="flex" justifyContent="flex-end" py={1}>
          <Button color="default" type="button" onClick={close}>
            Quitter
          </Button>
      </Box>
      </>
  );
};

ClaimDialogResult.propTypes = {
  validationResults: PropTypes.object,
  closeDialog: PropTypes.func,
};
