import React from 'react';
import {
  Edit,
  TabbedForm,
  FormTab,
  TextInput,
  useNotify,
  useRefresh,
  useRedirect,
  Toolbar,
  SaveButton,
  DeleteButton,
} from 'react-admin';

import { LayoutTitle } from '../Shared/Layout';
import { PropTypes } from '../../tools/types';

function CashbackAutodisplayPatternEdit(props) {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const onSuccess = async ({ data }) => {
    notify('Element updated');
    redirect(`/shops/${data.idShop}/show/cashback-autodisplay-patterns`, '/shops', data.idShop, {}, { loaded: true });
    refresh();
  };

  return (
  <Edit
    onSuccess={onSuccess}
    mutationMode="pessimistic"
    title={<LayoutTitle source="name" />}
    {...props}
  >
    <TabbedForm
      redirect="show"
      toolbar={<EditToolbar {...props} />}
    >
      <FormTab variant="standard" label="Résumé">
        <TextInput disabled source="id" label="id du pattern" />
        <TextInput disabled source="idShop" label="Id du shop" />
        <TextInput fullWidth source="pattern" label="Pattern" />
      </FormTab>
    </TabbedForm>
  </Edit>
  );
}

const EditToolbar = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const onDelete = async () => {
    notify('Element deleted');
    redirect(`/shops/${props.record.idShop}/show/cashback-autodisplay-patterns`, '/shops', props.record.idShop, {}, { loaded: true });
    refresh();
  };

  return (
    <Toolbar {...props}>
      <SaveButton />
      <DeleteButton mutationMode="pessimistic" onSuccess={onDelete} />
    </Toolbar>
  );
};

EditToolbar.propTypes = {
  record: {
    idShop: PropTypes.string,
  },
};

export default CashbackAutodisplayPatternEdit;
